import { USER_ROLES, useSSO } from '@orca/contexts-sso';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect } from 'react';

import Loader from '~/components/Loader';
import { routes } from '~/constants/routes';

export default function Home() {
  const route = useRouter();
  const { role } = useSSO();

  useEffect(() => {
    if (
      role === USER_ROLES.creatorEcho ||
      role === USER_ROLES.creatorFoam ||
      role === USER_ROLES.creatorManaged
    ) {
      route.push(routes.influencersMessages);
    } else {
      route.push(routes.default);
    }
  }, [route, role]);

  return <Loader />;
}
